<template>
  <div class="source">
    <div class="grid" ref="gridRef">
      <div class="grid-lie" v-for="(item,index) in array" :key="index">
        <div class="grid-ge" v-for="(item2,index2) in 7" :key="index2">
          <div class="scball b1" v-if="item[index2] == '单'">{{ item[index2] }}</div>
          <div class="scball b2" v-else-if="item[index2] == '双'">{{ item[index2] }}</div>
          <view class="scball" v-else>{{ item[index2] }}</view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";

export default {
  name: '',
  components: {},
  setup() {
    return {
      array: ref([])
    }
  },
  mounted() {
    setInterval(() => {
      this.getdata()
    },5000)
  },
  methods: {
    scrollRight() {
      let clientWidth = this.$refs.gridRef.clientWidth
      let scrollWidth = this.$refs.gridRef.scrollWidth
      console.log('clientWidth', clientWidth)
      console.log('scrollWidth', scrollWidth)
      if(scrollWidth > clientWidth) {
        this.$refs.gridRef.scrollLeft += 40
        console.log('scrollLeft', this.$refs.gridRef.scrollLeft)
      }
    },
    getdata() {
      let _this = this
      axios.get('https://hash.tridentst.com/api/block/pro/getChartBlocks?pageSize=100&type').then(res => {
        console.log(res.data);
        let data = res.data.obj.array
        let newlist = []
        data.forEach(item => {
          newlist.push(item.split(","))
        })
        _this.array = newlist
        _this.scrollRight()
      })
    }
  }
}
</script>

<style scoped>
  .source {
    padding: 15px;
  }

  .grid {
    display: flex;
    overflow: auto hidden;
    border-radius: 12px;
    background: linear-gradient(173deg,rgba(73,73,98,.31),rgba(53,52,86,.58));
    border: 1px solid #767595;
  }
  
  .grid-ge {
    padding: 5px;
    border: 1px solid hsla(0,0%,100%,.2);
  }

  .scball {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
  }

  .b1 {
    background: linear-gradient(180deg,#ff6393,#e73131);
  }

  .b2 {
    background: linear-gradient(180deg,#47ddc2,#0da271);
  }
</style>